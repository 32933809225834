<template>
  <section id="dashboard-ecommerce">
    <b-card>

      <b-row>
        <!-- User Info: Left col -->
        <b-col
            cols="21"
            xl="6"
            class="d-flex justify-content-between flex-column"
        >
          <b-row>
            <b-col cols="12">

              <div class="d-flex justify-content-start">
                <b-img
                    :src="company.logo"
                    height="136px"
                    :alt="company.name"
                />


              </div>

            </b-col>

          </b-row>

        </b-col>


        <!-- Right Col: Table -->
        <b-col
            cols="12"
            xl="6"
        >
          <div class="d-flex center-layout ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ company.name }}
              </h4>

            </div>

          </div>

          <table class="mt-2 mt-xl-0 w-100">

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Legal Address') }}</span>
              </th>
              <td class="pb-50">
                {{ company.address }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Operative Address') }}</span>
              </th>
              <td class="pb-50">
                {{ company.operative_address }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Activity') }}</span>
              </th>
              <td class="pb-50">
                {{ company.activity }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Ateco Sector') }}</span>
              </th>
              <td class="pb-50">
                {{ company.ateco }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Risk Class') }}</span>
              </th>
              <td class="pb-50">
                {{ $t(company.risk_class) }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Vat Number') }}</span>
              </th>
              <td class="pb-50">
                {{ company.vat_number }}
              </td>
            </tr>



            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Number of employees') }}</span>
              </th>
              <td class="pb-50">
                {{ company.number_of_employees }}
              </td>
            </tr>




            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ $t('Email 1') }}</span>
              </th>
              <td class="pb-50">
                {{ company.email1 }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ $t('Email 2') }}</span>
              </th>
              <td class="pb-50">
                {{ company.email2 }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">
                <span class="font-weight-bold">{{ $t('Email 3') }}</span>
              </th>
              <td class="pb-50 ">
                {{ company.email3 }}
              </td>
            </tr>
            <hr/>



            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Representative') }}</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ company.representative }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('RSPP') }}</span>
              </th>
              <td class="pb-50">
                {{ company.RSPP }}
              </td>
            </tr>


            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Nominated on') }}</span>
              </th>
              <td class="pb-50">
                {{ company.RSPP_nominated_on | moment('YYYY-MM-DD')}}
              </td>
            </tr>


            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('RLS') }}</span>
              </th>
              <td class="pb-50">
                {{ company.RLS }}
              </td>
            </tr>


            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('RLS elected on') }}</span>
              </th>
              <td class="pb-50">
                {{ company.RLS_elected_on| moment('YYYY-MM-DD') }}
              </td>
            </tr>

<tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Communication on') }}</span>
              </th>
              <td class="pb-50">
                {{ company.INAIL_communication_on | moment('YYYY-MM-DD')}}
              </td>
            </tr>


  <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Doctor') }}</span>
              </th>
              <td class="pb-50">
                {{ company.doctor }}
              </td>
            </tr>


            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Nominated on') }}</span>
              </th>
              <td class="pb-50">
                {{ company.doctor_nominated_on | moment('YYYY-MM-DD') }}
              </td>
            </tr>

            <hr/>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Vibration Evaluation Expiration') }}</span>
              </th>
              <td class="pb-50">
                {{ company.vibration_evaluation_expiration | moment('YYYY-MM-DD') }}
              </td>
            </tr>

            <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Noise Evaluation Expiration') }}</span>
              </th>
              <td class="pb-50">
                {{ company.noise_evaluation_expiration | moment('YYYY-MM-DD') }}
              </td>
            </tr>
  <tr>
              <th class="pb-50">

                <span class="font-weight-bold">{{ $t('Stress evaluation expiration') }}</span>
              </th>
              <td class="pb-50">
                {{ company.stress_evaluation_expiration | moment('YYYY-MM-DD') }}
              </td>
            </tr>


            <tr>
              <th class="pb-50">

              </th>
              <td class="pb-50" style="text-align: right;">
                <b-button
                    :to="{ name: 'company', params: { id: company.id } }"
                    variant="primary"
                >
                  {{ $t('Edit') }}
                </b-button>

              </td>
            </tr>


          </table>
        </b-col>
      </b-row>
    </b-card>


  </section>
</template>

<script>
import {BRow, BCol, BCard, BButton, BAvatar, BImg,} from 'bootstrap-vue'

import {getUserData} from '@/auth/utils'

import UserService from "@/basic/userService";
import useJwt from "@/auth/jwt/useJwt";
import ability from "@/libs/acl/ability";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BImg,
  },
  methods: {
    refresh() {

      const userService = new UserService(useJwt)

      let company_id = this.userData.company.id


      userService.company(company_id).then(response => {
        this.company = response.data.data.company;

        this.company_modules = response.data.data.company_modules;


      })


    },

  },
  data() {
    return {
      data: {},
      company_modules: {},
      company: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      is_admin: ability.can("manage", "admin"),
    }
  },
  created() {
    console.log('userData', this.userData)
    this.company = this.userData.company;
    this.refresh()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
